import ProximusClaimObjectModel from './ClaimObjectModel';
import ProximusDeviceObjectModel from './DeviceObjectModel';

import ClaimStatusHistoryElement from './ClaimStatusHistoryElement';
// import ClaimCostObject from '@src/api/models/claim/ClaimCostObject';

class ClaimSaveModel {
    readonly claim: ProximusClaimObjectModel;
    readonly device: ProximusDeviceObjectModel;
    readonly claimCostDetails: Array<Record<string, any>>;
    readonly claimDescriptionHistories: Array<Record<string, any>> | null;
    readonly claimSHistories: Array<Record<string, any>>;
    readonly damageSubtypes: Array<number>;
    readonly other: string;
    readonly orderStatusId: any;

    constructor(claim: Record<string, any>, userData: Record<string, any>, deviceData: Record<string, any>, defaultDamage: any, data: any) {
        this.claim = new ProximusClaimObjectModel(claim, userData);
        this.device = new ProximusDeviceObjectModel(deviceData, userData);
        this.claimCostDetails = ClaimSaveModel.generateClaimCostDetails(data);
        // this.claimDescriptionHistories = claim?.descriptionLogArea !== undefined ? ClaimSaveModel.generateClaimDescriptionHistories() : null;
        this.claimSHistories = [new ClaimStatusHistoryElement(claim, userData)];
        this.damageSubtypes = [defaultDamage?.id];
        // this.other = claimForm?.damageSubtypeOther;
        // this.orderStatusId = claim?.clm_status;
    }

    private static generateClaimCostDetails = (data): Array<Record<string, any>> => {
        const transportCosts = [];
        const rmaCosts = [];
        const laborCosts = [];
        const partsCost = data?.claimCostDetails ? data?.claimCostDetails : [];
        const franchiseCosts = [];
        const expertiseCosts = [];
        const variousCosts = [];
        const voucherCosts = [];
        // if (claimForm.transportCosts !== undefined && claimForm?.transportCosts?.length > 0) {
        //     transportCosts = claimForm.transportCosts.map((item) => new ClaimCostObject(item, dataObject.userName, 1, dataObject?.claimId));
        // }
        // if (claimForm.rmaCosts !== undefined && claimForm?.rmaCosts?.length > 0) {
        //     rmaCosts = claimForm.rmaCosts.map((item) => new ClaimCostObject(item, dataObject.userName, 2, dataObject?.claimId));
        // }
        // if (claimForm.laborCosts !== undefined && claimForm?.laborCosts?.length > 0) {
        //     laborCosts = claimForm.laborCosts.map((item) => new ClaimCostObject(item, dataObject.userName, 3, dataObject?.claimId));
        // }
        // if (claimForm.partsCosts !== undefined && claimForm?.partsCosts?.length > 0) {
        //     partsCost = claimForm.partsCosts.map((item) => new ClaimCostObject(item, dataObject.userName, 4, dataObject?.claimId));
        // }
        // if (claimForm.franchiseCosts !== undefined && claimForm?.franchiseCosts?.length > 0) {
        //     franchiseCosts = claimForm.franchiseCosts.map((item) => new ClaimCostObject(item, dataObject.userName, 5, dataObject?.claimId));
        // }
        // if (claimForm.expertiseCosts !== undefined && claimForm?.expertiseCosts?.length > 0) {
        //     expertiseCosts = claimForm.expertiseCosts.map((item) => new ClaimCostObject(item, dataObject.userName, 6, dataObject?.claimId));
        // }
        // if (claimForm.variousCosts !== undefined && claimForm.variousCosts.length > 0) {
        //     variousCosts = claimForm.variousCosts.map((item) => new ClaimCostObject(item, dataObject.userName, 7, dataObject?.claimId));
        // }
        // if (claimForm.voucherCosts !== undefined && claimForm?.voucherCosts?.length > 0) {
        //     voucherCosts = claimForm?.voucherCosts?.map((item) => new ClaimCostObject(item, dataObject.userName, 8, dataObject?.claimId));
        // }
        return [...transportCosts, ...rmaCosts, ...laborCosts, ...partsCost, ...franchiseCosts, ...variousCosts, ...expertiseCosts, ...voucherCosts];
    };

    private static generateClaimDescriptionHistories = (claimForm: Record<string, any>, dataObject: Record<string, any>): Array<Record<string, any>> => {
        const historyObject = {
            t_clm_prt_id: dataObject?.partnerId,
            t_clm_clm_id: dataObject?.claimId,
            t_clm_con_number: dataObject?.contractNumber,
            description: claimForm?.descriptionLogArea,
            activity_master_id: claimForm?.descriptionLogType?.value,
            users_user_name: dataObject?.userName,
        };
        return new Array(historyObject);
    };
}

export default ClaimSaveModel;
