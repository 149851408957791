import dateFormatter from '@src/components/Utility/util';

class ClaimObjectModel {
    readonly breakdown_date: string;
    readonly clm_brief_desc_id: number | string;
    // readonly clm_cancel_rsn_id: number | string;
    // readonly clm_comp_type_id: number | string;
    // readonly clm_cost_total: number;
    // readonly clm_date: string;
    readonly clm_register: string;
    readonly clm_description: string;
    readonly clm_id: number | string;
    readonly clm_status: number | string;
    readonly clm_type: string;
    readonly con_number: number | string;
    readonly created: string;
    // readonly damageplacefreetext: string;
    readonly deviceid: number;
    readonly claim_ewtype_code: number;
    // readonly parent_clm_id: number | string;
    // readonly parent_prt_id: number | string;
    // readonly possessorofdevicetypeid: number;
    // readonly proved_by_imei_history: number | any;
    readonly prt_id: string;
    readonly user_name: string;
    // readonly findMyIphoneId: number;

    constructor(claim: Record<string, any>, userData: Record<string, any>) {
        this.breakdown_date = dateFormatter(new Date(claim?.breakdown_date));
        // this.clm_date = dateFormatter(new Date());
        this.clm_brief_desc_id = claim?.clm_brief_desc_id;
        // this.clm_id = claim?.clm_id;
        this.clm_register = dateFormatter(new Date());
        this.clm_description = claim?.clm_description;
        this.clm_status = claim?.clm_status * 1;
        this.clm_type = claim?.clm_type;
        this.con_number = userData?.contractNumber;
        this.created = dateFormatter(new Date());
        this.deviceid = userData?.deviceId;
        this.claim_ewtype_code = userData?.ewType?.ewTypeCode;
        this.prt_id = userData?.partnerId;
        this.user_name = userData?.customerName;
    }
}
export default ClaimObjectModel;
