import EditIcon from 'mdi-react/EditIcon';
import SaveIcon from 'mdi-react/FloppyIcon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ContractIcon from '@mui/icons-material/Article';

import RootState from '@src/interfaces/RootState';

import renderTextField from '../Form/TextField';
import { Box, Button, CardHeader, Grid } from '@mui/material';
import RenderTextField from '../Form/TextField';
import { validateRealIBAN } from '../Utility/util';
import dayjs from 'dayjs';
import { UPDATE_CLAIM_INVOICE } from '@src/redux/claim/actions';

interface ClaimCardsProps {
    claim;
}

const ClaimCard: React.FC<
    // eslint-disable-next-line @typescript-eslint/ban-types
    ClaimCardsProps
> = ({ claim }) => {
    const [editBankAccount, setEditBankAccount] = useState<boolean>(false);
    const [editedIBAN, setEditedIBAN] = useState(null);
    const [IBANResult, setIBANResult] = useState<unknown>(true);

    const dispatchStore = useDispatch();
    const { t } = useTranslation('common');

    const updateClaimInvoice = () => {
        const payload = [
            {
                clm_id: claim.claim_id,
                id: 0,
                invoice_date: dayjs(new Date()).format('DD-MM-YYYY'),
                invoice_reception_date: dayjs(new Date()).format('DD-MM-YYYY'),
                invoice_reference: editedIBAN,
                repairer_id: 1,
                tentative_value: 0,
                type_id: 7,
                user_name: JSON.parse(sessionStorage.getItem('contract') as string).customerName,
            },
        ];
        dispatchStore({ type: UPDATE_CLAIM_INVOICE, val: payload });
    };
    const handleEdit = () => {
        if (editBankAccount === false) {
            setEditBankAccount(true);
        } else {
            updateClaimInvoice();
            setEditBankAccount(false);
        }
    };

    const validateIban = (e) => {
        setEditedIBAN(e.target.value);
        const result = validateRealIBAN(e.target.value);
        if (result === true) {
            setIBANResult(true);
        } else {
            setIBANResult(false);
        }
    };

    return (
        // <form onSubmit={handleSubmit}>
        <Box sx={{ border: '1px solid rgba(0,0,0,0.175)', borderRadius: '5px' }}>
            <CardHeader sx={{ backgroundColor: '#dddd', textTransform: 'uppercase' }} avatar={<ContractIcon />} title={t('claim_list.claim')} />
            <Grid container justifyContent={'center'} sx={{ width: '100%', padding: '1rem 0' }} spacing={1}>
                <Grid item xs={10}>
                    {Object.entries(claim).map((item, index) => {
                        let value;
                        if (item[1] === undefined || item[0] === 'clm_status_id') return null;
                        if (item[0] === 'clm_status') {
                            value = t(`${item[1]}`);
                        } else if (item[0] === 'bank_account_number') {
                            value = editedIBAN ?? item[1];
                        } else value = item[1];
                        return (
                            <Box
                                className={`claim-card__field ${item[0] === 'clm_status' ? `history-status-${claim.clm_status_id}` : ''}`}
                                key={index}
                                sx={{ position: 'relative', margin: '.7rem 0' }}
                            >
                                <RenderTextField
                                    key={index}
                                    onChange={validateIban}
                                    disabled={item[0] !== 'bank_account_number' || !editBankAccount}
                                    label={t(`claim_list.${item[0]}`)}
                                    error={item[0] === 'bank_account_number' ? !IBANResult : false}
                                    helperText={item[0] === 'bank_account_number' && IBANResult !== true ? t('validations.invalid_iban') : null}
                                    value={item[0] === 'bank_account_number' ? editedIBAN ?? value : value}
                                />
                                {item[0] === 'bank_account_number' ? (
                                    <Box
                                        sx={{ position: 'absolute', top: '5px', right: '5px', cursor: IBANResult === false ? 'not-allowed' : 'pointer' }}
                                        onClick={() => {
                                            if (IBANResult === false) {
                                                return null;
                                            } else {
                                                handleEdit();
                                            }
                                        }}
                                    >
                                        {editBankAccount === false ? (
                                            <Box sx={{ borderRadius: '5px', padding: '.5rem', bgcolor: '#000000' }}>
                                                <EditIcon color={'white'} />
                                            </Box>
                                        ) : (
                                            <Box sx={{ borderRadius: '5px', padding: '.5rem', bgcolor: '#ac0209', opacity: IBANResult === false ? '.5' : '1' }}>
                                                <SaveIcon color={'white'} />
                                            </Box>
                                        )}
                                    </Box>
                                ) : (
                                    ''
                                )}
                            </Box>
                        );
                    })}
                </Grid>
            </Grid>
        </Box>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default ClaimCard;
