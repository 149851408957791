import dateFormatter from '@src/components/Utility/util';

class DeviceObjectModel {
    readonly colormasterid: number | string;
    readonly imei: number | string;
    readonly memorysizeid: number | string;
    readonly id: number | string;
    readonly modelmasterid: number | string;
    readonly purchaseDate: string;
    readonly purchasePrice: number | string;
    readonly tb_brandid: number | string;
    readonly tg_prtid: string;
    readonly tg_grpid: string;
    // readonly modelfreetext: string;

    constructor(deviceData: Record<string, any>, userData: Record<string, any>) {
        this.colormasterid = deviceData?.colormasterid;
        this.imei = deviceData?.serialNumber;
        this.memorysizeid = deviceData?.memorysizeid;
        // this.id = deviceData?.device?.id;
        this.modelmasterid = deviceData?.model?.id;
        this.purchaseDate = dateFormatter(new Date());
        this.purchasePrice = deviceData?.purchasePrice;
        this.tb_brandid = deviceData?.brand?.id;
        this.tg_prtid = userData?.partnerId;
        this.tg_grpid = deviceData?.group?.id;
    }
}
export default DeviceObjectModel;
