import axiosInstance from './axiosSetup';
import { LoginModel } from './models/Login';

export default class Service {
    /**
     * AUTHORIZATION
     */
    static performLogin = (body: LoginModel) =>
        axiosInstance
            .post('authentications/microsite-login', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });
    // static performLogin = (body: LoginModel) =>
    //     axiosInstance
    //         .post('2factor/login', body)
    //         .then((res) => {
    //             return res;
    //         })
    //         .catch((err) => {
    //             throw err;
    //         });
    static verifyCode = (body: any) =>
        axiosInstance
            .post('authentications/microsite-verification', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });
    // static verifyCode = (body: LoginModel) =>
    //     axiosInstance
    //         .post('2factor/verify', body)
    //         .then((res) => {
    //             return res;
    //         })
    //         .catch((err) => {
    //             throw err;
    //         });
    static refreshToken = () =>
        axiosInstance
            .get('refresh/token')
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });
    static getContract = (partnerId: string, contractNumber: string) =>
        axiosInstance.get<any>(`contracts/${partnerId}/${contractNumber}`).then((response) => {
            return response;
        });
    static getAccount = (partnerId: string, contractNumber: string) =>
        axiosInstance.get<any>(`contracts/${partnerId}/${contractNumber}/account`).then((response) => {
            return response;
        });
    static getDevice = (deviceId: string) =>
        axiosInstance.get<any>(`devices/${deviceId}`).then((response) => {
            return response;
        });
    /**
     * CLAIM
     */
    static getDamageSubTypes = () =>
        axiosInstance.get<any>(`damage/subtype/1`).then((response) => {
            return response;
        });
    static claimAdd = (body: Record<string, any>) =>
        axiosInstance.post<any>('proximus/upsert/claim', body).then((response) => {
            return response;
        });
    static costDetailAdd = (partnerId: string, claimId: string, body: Record<string, any>) =>
        axiosInstance.post<any>(`claims/${partnerId}/${claimId}/cost-detail`, body).then((response) => {
            return response;
        });
    static uploadFile = (body: Record<string, any>) =>
        axiosInstance.post<any>('attachments', body).then((response) => {
            return response;
        });
    // static claimAdd = (body) =>
    //     axiosInstance
    //         .post('bwg/claim/upsert', body)
    //         .then((res) => {
    //             return res;
    //         })
    //         .catch((err) => {
    //             throw err;
    //         });
    static updateAccount = (partnerId: string, contractNumber: string, body: Record<string, any>) =>
        axiosInstance
            .patch(`contracts/${partnerId}/${contractNumber}/account`, body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });
    static updateClaimInvoice = (body) =>
        axiosInstance
            .post('claim/update/invoice', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });
    static claimList = (contractNumber: string) =>
        axiosInstance
            .get(`claims?con_number=${contractNumber}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });
}

// const login = <T>(body: Record<string, any>, queryParams?: Record<string, any>): Observable<T | void> => {
//     delete axiosInstance.defaults.headers.common['Authorization'];
//     delete axiosInstance.defaults.headers.common['captcha-response'];
//     return defer(() =>
//         axiosInstance.post<T>(`2factor/login`, body, {
//             params: queryParams,
//         }),
//     ).pipe(map((result: any) => result));
// };

// const verifyCode = <T>(body: Record<string, any>, queryParams?: Record<string, any>): Observable<T | void> => {
//     delete axiosInstance.defaults.headers.common['Authorization'];
//     delete axiosInstance.defaults.headers.common['captcha-response'];
//     return defer(() =>
//         axiosInstance.post<T>(`2factor/verify`, body, {
//             params: queryParams,
//         }),
//     ).pipe(map((result: any) => result));
// };

// const tokenRefresh = <T>(token: string, queryParams?: Record<string, any>): Observable<T | void> => {
//     axiosInstance.defaults.headers.common['Authorization'] = token;
//     return defer(() =>
//         axiosInstance.get<T>(`refresh/token`, {
//             params: queryParams,
//         }),
//     ).pipe(map((result: any) => result));
// };

// const claimTypeList = <T>(token: string, queryParams?: Record<string, any>): Observable<T | void> => {
//     axiosInstance.defaults.headers.common['Authorization'] = token;
//     return defer(() =>
//         axiosInstance.get<T>(`claim/type/list`, {
//             params: queryParams,
//         }),
//     ).pipe(map((result: any) => result));
// };

// const claimAdd = <T>(token: string, body: Record<string, any>, queryParams?: Record<string, any>): Observable<T | void> => {
//     axiosInstance.defaults.headers.common['Authorization'] = token;
//     return defer(() =>
//         axiosInstance.post<T>(`bwg/claim/upsert`, body, {
//             params: queryParams,
//         }),
//     ).pipe(map((result: any) => result));
// };

// const claimList = <T>(token: string, body: Record<string, any>, queryParams?: Record<string, any>): Observable<T | void> => {
//     axiosInstance.defaults.headers.common['Authorization'] = token;
//     return defer(() =>
//         axiosInstance.get<T>(`claims`, {
//             params: queryParams,
//         }),
//     ).pipe(map((result: any) => result));
// };

// const userUpdate = <T>(token: string, body: Record<string, any>, queryParams?: Record<string, any>): Observable<T | void> => {
//     axiosInstance.defaults.headers.common['Authorization'] = token;
//     return defer(() =>
//         axiosInstance.post<T>(`bwg/contract/edit`, body, {
//             params: queryParams,
//         }),
//     ).pipe(map((result: any) => result));
// };

// const updateClaimInvoice = <T>(token: string, body: Record<string, any>, queryParams?: Record<string, any>): Observable<T | void> => {
//     axiosInstance.defaults.headers.common['Authorization'] = token;
//     return defer(() =>
//         axiosInstance.post<T>('claim/update/invoice', body, {
//             params: queryParams,
//         }),
//     ).pipe(map((result: any) => result));
// };

// export default {
//     login,
//     verifyCode,
//     tokenRefresh,
//     claimTypeList,
//     claimAdd,
//     claimList,
//     userUpdate,
//     updateClaimInvoice,
// };
