class ClaimStatusHistoryElement {
    prt_id: number | string;
    change_date: Date | number;
    user_name: string;
    clm_status: number;
    clm_id?: number;

    constructor(claim: Record<string, any>, userData: Record<string, any>) {
        this.prt_id = userData?.contract?.prt_id;
        this.change_date = Date.now();
        this.user_name = userData?.contract?.customername;
        this.clm_status = claim.clm_status * 1;
        // this.clm_id = claimId;
    }
}

export default ClaimStatusHistoryElement;
